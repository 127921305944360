import "./components/_scaleRoot";
$(".inner").scaleRoot({
  scaleMode: viewportIsMobile($("body").outerWidth())
    ? "containWidth"
    : "cover",
});

function viewportIsMobile(width) {
  return width < 1025;
}

import "./components/_lightbox";

import MobileDetect from "mobile-detect";

var md = new MobileDetect(window.navigator.userAgent);

if ($(".getLink").length > 0) {
  switch (md.os()) {
    case "AndroidOS":
      // $(".getLink").addClass("open-download-and");
      // $(".open-download-and").click(function (e) {
      //     e.preventDefault();

      //     $(".popup--download-android").addClass("active");
      // });
      // break;
      $(".getLink").attr("href", $(".getLink-and").attr("href"));
      break;
    case "iOS":
      $(".getLink").attr("href", $(".getLink-ios").attr("href"));
      break;
    default:
  }
}

import "./components/_content-tab";
import "./components/_frame-mp";
import "./components/_control-side";
// import "./components/_progress";
