import "lazysizes";
import Scrollbar from "smooth-scrollbar";
import BezierEasing from "bezier-easing";

// SCROLL
// -- Init
var scaleratio = $(window).outerHeight() / 1000;
var aniComplete = true;

if ($(".scrollContent").length > 0) {
  if (!viewportIsMobile($("body").outerWidth())) {
    var vdamping = 0.1;
    let scrollbars = {};
    scrollbars["root"] = Scrollbar.init($(".scrollContent")[0], {
      alwaysShowTracks: true,
      damping: vdamping,
    });
    let frameSwipe = {
      total: 3,
      frames: [".section--1", ".section--2", ".section--footer"],
      navigation: ".nav-left li",
      index: 0,
      isEndAnimate: true,
      animateDelay: 400,
      animateEndDelay: 400,
    };
    scrollbars["root"].addListener(function (status) {
      if (
        status.offset.y >= 300 &&
        !$(".monphai-container").hasClass("active")
      ) {
        $(".monphai-container").addClass("active");
      }
      if (status.offset.y >= 1500 && !$(".chars").hasClass("active")) {
        $(".chars").addClass("active");
      }
      //frameSwipe.scrollTop = status.offset.y;
      // if (frameSwipe.isEndAnimate){
      //   for( var i = 0;  i < frameSwipe.total ; i++){
      //     if(frameSwipe.scrollTop <= $(frameSwipe.frames[0])[0].offsetTop * scaleratio + 200 * scaleratio){
      //       frameSwipe.index = 0;
      //     }
      //     else if ( frameSwipe.scrollTop <= $(frameSwipe.frames[i])[0].offsetTop * scaleratio - 200 * scaleratio ){
      //        frameSwipe.index = i;
      //        break;
      //     }

      //   }
      //   $(frameSwipe.navigation).find("a").removeClass("active");
      //   $(frameSwipe.navigation).eq(frameSwipe.index).find("a").addClass("active");
      // }
    });
    $(frameSwipe.navigation).each(function () {
      $(this)
        .find("a")
        .on("click", function (e) {
          e.preventDefault();
          if (frameSwipe.isEndAnimate) {
            frameSwipe.isEndAnimate = false;
            var frameName = $(this).data("scroll-link");
            frameSwipe.index = frameSwipe.frames.indexOf(frameName);
            scrollbars["root"].scrollTo(
              0,
              $($(this).data("scroll-link"))[0].offsetTop,
              frameSwipe.animateDelay,
              {
                easing: (p) => {
                  var easing = BezierEasing(0.86, 0, 0.07, 1);
                  return easing(p);
                },
                callback: () => {
                  frameSwipe.isEndAnimate = true;
                  // $(frameSwipe.navigation).find("a").removeClass("active");
                  // $(frameSwipe.navigation).eq(frameSwipe.index).find("a").addClass("active");
                },
              }
            );
          }
        });
    });
  } else {
    $(".scrollLink").each(function () {
      $(this).on("click", function (e) {
        e.preventDefault();
        $(".scrollLink").removeClass("active");
        $(this).addClass("active");
        if (aniComplete == true) {
          aniComplete = false;
          $("body,html").animate(
            {
              scrollTop: $($(this).data("scroll-link")).offset().top,
            },
            600,
            function () {
              aniComplete = true;
            }
          );
        }
      });
    });
  }
}
if ($(".scrollArticle").length > 0) {
  scrollbars["article"] = Scrollbar.init($(".scrollArticle")[0], {
    alwaysShowTracks: true,
  });
}
if ($(".scrollPopup").length > 0) {
  scrollbars["popup"] = Scrollbar.init($(".scrollPopup")[0], {
    alwaysShowTracks: true,
  });
}

function viewportIsMobile(width) {
  return width < 1025;
}

import Swiper from "Swiper";
import "swiper/css/swiper.min.css";
var swiper = new Swiper(".mySwiper", {
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});
