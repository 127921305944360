var close = false;
$(".close-side").click(function (e) {
  e.preventDefault();
  if (!close) {
    $(this).addClass("toOpen");
    $(".aside-right").addClass("closeAsideright");
    close = true;
  } else {
    $(this).removeClass("toOpen");
    $(".aside-right").removeClass("closeAsideright");
    close = false;
  }
});

var closeNav = false;
$(".open-nav").click(function (e) {
  e.preventDefault();
  if (!closeNav) {
    $(this).addClass("toClose");
    $(".sub-nav-mb").addClass("Open");
    closeNav = true;
  } else {
    $(this).removeClass("toClose");
    $(".sub-nav-mb").removeClass("Open");
    closeNav = false;
  }
});

// var player =  $(".videoloading")[0];

// console.log(player);
// player.onended = function() {
//     player.pause();
//     $(".outer--loading").hide();
//     $(".videoloading").hide();
//   };
