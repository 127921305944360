// import Swiper from "Swiper";
// import "swiper/css/swiper.min.css";

// var swipermp = new Swiper(".monphai-swiper", {
//   grabCursor: true,
//   centeredSlides: true,
//   effect: "fade",
//   navigation: {
//     nextEl: ".mp-next",
//     prevEl: ".mp-back",
//   },
//   pagination: {
//     el: ".mp-pag",
//     clickable: true,
//   },
//   loop: true,
//   preloadImages: false,
//   // Enable lazy loading
//   lazy: {
//     loadPrevNext: true,
//   },
//   slidesPerView: 1,
// });

// var swiperf5 = new Swiper(".swiper-f5", {
//   pagination: {
//     el: ".swiper-pagination-f5",
//     clickable: true,
//   },
//   effect: "fade",
//   preloadImages: false,
//   // Enable lazy loading
//   lazy: {
//     loadPrevNext: true,
//   },

//   slidesPerView: 1,
// });
// var swiperf7 = new Swiper(".swiper-f7", {
//   pagination: {
//     el: ".swiper-pagination-f7",
//     clickable: true,
//   },
//   effect: "coverflow",
//   loop: true,
//   slidesPerView: "auto",
//   centeredSlides: true,
//   coverflowEffect: {
//     rotate: 0,
//     stretch: 100,
//     depth: 400,
//     modifier: 1,
//     slideShadows: false,
//   },
//   preloadImages: false,
//   // Enable lazy loading
//   lazy: {
//     loadPrevNext: true,
//   },
// });

// // var f4_tab1_swiper = new Swiper('.f4-tab1-swiper', {
// //   grabCursor: true,
// //   effect :"fade",
// //   pagination: {
// //     el: '.f4-tab1-swiper-pag',
// //     clickable: true,
// //   },
// //   loop: true,
// //   preloadImages: false,
// //   // Enable lazy loading
// //   lazy: {
// //     loadPrevNext: true,
// //     loadPrevNextAmount: 1,
// //   },
// //   slidesPerView: 1,
// // });
var target;
var control;
$(".btn-tab").click(function (e) {
  e.preventDefault();
  target = $(this).data("target");
  $(".btn-tab").removeClass("active");
  $(this).addClass("active");
  $(".tab").removeClass("active");
  $(target).addClass("active");
});
